import React, {useRef} from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import Joi from 'joi';
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import email from "../../services/emailService";
import useForm from '../../hooks/useForm';
import logger from '../../services/logService';
import Icon from '../common/icon/Icon';
import Link from '../common/navigation/links/Link';
import Container from '../common/container/Container';
import Box from '../common/container/box/Box';
import Body, {Section} from '../common/container/Body';
import Title, { H2, H4 } from '../common/container/text/Title';
import phoneIcon from '../../assets/icons/phone.svg';
import sendIcon from '../../assets/icons/paper-plane.svg';
import workdeskImg from '../../assets/images/supernova_img_workdesk.jpg';
import IconGroup from '../common/icon/IconGroup';
import IconLink from '../common/navigation/links/IconLink';
import { linkedinUrl, facebookUrl, instagramUrl, meetingUrl, recaptchaKey } from "../../config.json";
import facebookIcon from '../../assets/icons/facebook-square.svg';
import linkedInIcon from '../../assets/icons/linkedin.svg';
import instagramIcon from '../../assets/icons/instagram-square.svg';
import bookingIcon from '../../assets/icons/calendar-days.svg';

// TODO: add translation to error messages
// TODO: add translation to toast messages

export default function Contact() {
    const isRequired = true;
    const recaptchaRef = useRef();
    const { i18n, t } = useTranslation("home");
    const initialData = { name: "", company: "", phone: "", email: "", case: "", message: "" };
    const caseOptions = [
        { id: "quotation", name: t("contact.caseOptions.quotation") }, 
        { id: "support", name: t("contact.caseOptions.support") },
        { id: "other", name: t("contact.caseOptions.other") }
    ];
    const schema = Joi.object({
        name: Joi.string().allow("").optional().label("Name"),
        company: Joi.string().allow("").optional().label("Company"),
        phone: Joi.string().allow("").optional().regex(/^[+]*[0-9]{0,3}[-]*[0-9]{2,4}[-]*[0-9]{5,7}$/).label("Phone"),
        email: Joi.string().email({ tlds: { allow: false } }).required().label("E-mail"),
        case: Joi.string().min(5).required().label("Subject"),
        message: Joi.string().min(5).max(100).required().label("Message")
    });

    const doSubmit = async () => {
        try {
            const token = await recaptchaRef.current.executeAsync();
            console.log("Captcha value:", token);



            email.send(data);
            toast.success("Message sent");
        } catch (error) {
            toast.error("Message cannot be sent");
            logger.error(error.response.data);
        }
    };

    const { handleSubmit, renderInput, renderTextArea, renderSelect, renderButton, data } = useForm(initialData, schema, doSubmit);

    return (
        <>
            <Container id="contact" rowColNum={1} useOffset>
                <Box minHeight="33vh" image={workdeskImg} />
            </Container>
            <Container rowColNum={2}>
                <Box left color="#FFFFFF">
                    <Title value={t("contact.title")} color="#071630" />
                    <Body itemScope itemType="http://schema.org/LocalBusiness" >
                        <h1 itemProp="name" style={{ display: "none" }}>Supernova Technology AB</h1>
                        <Section>
                            <H2 color="#071630">{t("contact.phone.title")}</H2>
                            <Link to="tel:04268076" color="#071630" size="1.28rem" external itemProp="telephone">
                                <Icon icon={phoneIcon} size="1rem" color="#071630" />
                                042 - 680 76
                            </Link>
                            <H4 color="#071630" indent="1.6rem">{t("contact.phone.description")}</H4>
                        </Section>
                        <Section>
                            <H2 color="#071630">{t("contact.meeting.title")}</H2>
                            <Link external to={meetingUrl} color="#071630" size="1.28rem">
                                <Icon icon={bookingIcon} size="1rem" color="#071630" />
                                {t("common:button.bookMeeting")}
                            </Link>
                            <H4 color="#071630" indent="1.6rem">{t("contact.meeting.description")}</H4>
                        </Section>
                        <Section>
                            <H2 color="#071630">{t("contact.socialmedias.title")}</H2>
                            <IconGroup>
                                <IconLink icon={linkedInIcon} to={linkedinUrl} size="32px" external />
                                <IconLink icon={facebookIcon} to={facebookUrl} size="32px" external />
                                <IconLink icon={instagramIcon} to={instagramUrl} size="32px" external />
                            </IconGroup>
                        </Section>
                    </Body>
                </Box>
                <Box color="#071630" margin="1rem" rounded>
                    <Form onSubmit={handleSubmit}>
                        {renderInput("name", t("contact.form.name.label"), "text", t("contact.form.name.hint"))}
                        {renderInput("company", t("contact.form.company.label"), "text", t("contact.form.company.hint"))}
                        {renderInput("phone", t("contact.form.phone.label"), "tel", t("contact.form.phone.hint"))}
                        {renderInput("email", t("contact.form.email.label"), "text", t("contact.form.email.hint"), isRequired)}
                        {renderSelect("case", t("contact.form.case.label"), caseOptions, t("contact.form.case.hint"), isRequired)}
                        {renderTextArea("message", t("contact.form.message.label"), t("contact.form.message.hint"), isRequired)}
                        {renderButton(t("contact.form.submit.label"), "submit", sendIcon, "#FFFFFF", {maxWidth: true, outline: true})}
                        <ReCAPTCHA sitekey={recaptchaKey} ref={recaptchaRef} size="invisible" hl={i18n.language} badge="inline" style={{"marginTop": "2rem"}} />
                    </Form>
                </Box>
            </Container>
        </>
    );
}


const Form = styled.form`
    width: 100%;
    max-width: 100%;
`;
