import React from 'react';
import styled from 'styled-components';
import Icon from '../icon/Icon';
import Button from '../navigation/buttons/Button';
import Modal from './Modal';
import closeIcon from '../../../assets/icons/xmark.svg';

export default function FormModal({title, children, show, cancelButtonLabel, onSubmit, onCancel}) {
    return (
        <form onSubmit={onSubmit}>
            <Modal title={title} show={show} onClose={onCancel} hideDefault>
                {children}
                <Section>
                    <Button onClick={onCancel} color="#CCCCCC" maxWidth outline>
                        <Icon icon={closeIcon} size="20px" color="#CCCCCC" />
                        {cancelButtonLabel}
                    </Button>
                </Section>
            </Modal>
        </form>
    );
}


const Section = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
`;
