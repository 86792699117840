import React from 'react';
import styled from 'styled-components';

// TODO: clean up card & box, extract common style and code, remove unused parts and rename for convienence.

export default function CardContent({left, children, ...rest}) {
    return (
        <Container left={left} {...rest}>{children}</Container>
    );
}


const Container = styled.div`
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: ${({left}) => left ? "flex-start" : "center"};
    padding: 48px;
    margin: 0;
    background-color: transparent;

    @media screen and (max-width: 800px) {
        justify-content: flex-start;
        justify-items: stretch;
    }
`;
