import React from 'react';
import styled from 'styled-components';

export default function BulletList({children, ...rest}) {
    return (<List {...rest}>{children}</List>);
}


const List = styled.ul`
    list-style-type: disc;
    list-style-image: ${({image}) => image ? `url(${image})` : null};
    list-style-position: outside;
    margin-left: 1rem;
`;

export const ListItem = styled.li`
    margin: 1rem 0;
`;
