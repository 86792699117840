import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Label from "./Label";
import { errorTextStyle, hintStyle, inputStyle } from '../../../style/common.css';
import InputContainer from './InputContainer';

export default function FormSelect({ data, name, label, value, error, helpText, readOnly, inline, idProperty, nameProperty, ...rest }) {
    return (
        <InputContainer error={error} inline={inline}>
            <StyledSelect
                {...rest}
                id={name}
                name={name}
                value={value}
                aria-labelledby={name + "Label"}
                readOnly={readOnly}
                disabled={readOnly}>
                <option disabled hidden value=""></option>
                {data.map((item) => (<option key={item[idProperty]} value={item[idProperty]}>{item[nameProperty]}</option>))}
            </StyledSelect>
            {label && <Label name={name} label={label} />}
            {error && helpText && <HelpText id={name + "Help"}>{helpText}</HelpText>}
            {error && <ErrorText id={name + "Validation"}>{error}</ErrorText>}
        </InputContainer>
    );
}


const StyledSelect = styled.select`
    ${inputStyle}
`;

const HelpText= styled.p`
    ${hintStyle}
`;

const ErrorText = styled.p`
    ${errorTextStyle}
`;


FormSelect.propTypes = {
    data: PropTypes.array.isRequired, // TODO: add more specific array content-type
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpText: PropTypes.string,
    readOnly: PropTypes.bool,
    inline: PropTypes.bool,
    idProperty: PropTypes.string.isRequired,
    nameProperty: PropTypes.string.isRequired
};

FormSelect.defaultProps = {
    name: "",
    label: "",
    value: "",
    readOnly: false,
    inline: false,
    idProperty: "id",
    nameProperty: "name"
};
