import React, { useState } from 'react';
import styled from 'styled-components';
import ContactModal from '../ContactModal';
import Countdown from './Countdown';
import IconLink from '../common/navigation/links/IconLink';
import Box from '../common/container/box/Box';
import Container from '../common/container/Container';
import FixedBar from '../common/FixedBar';
import Icon from '../common/icon/Icon';
import { linkedinUrl, facebookUrl, meetingUrl, bookingUrl } from "../../config.json";
import logo from '../../assets/icons/logo/Supernova_logo_star_color_v2.svg';
import calendarIcon from '../../assets/icons/calendar-days.svg';
import envelopeIcon from '../../assets/icons/envelope.svg';
import facebookIcon from '../../assets/icons/facebook-f.svg';
import linkedInIcon from '../../assets/icons/linkedin-in.svg';
import userGraduateIcon from '../../assets/icons/user-graduate.svg';

export default function Maintenance() {
    const [showContact, setShowContact] = useState(false);

    const handleShowContact = () => setShowContact(!showContact);

    return (
        <MainContainer>
            <ContactModal show={showContact} onHideModal={handleShowContact} />
            <FixedBar top right="10px">
                <Icon icon={envelopeIcon} size="50px" color="#CCCCCC" onClick={handleShowContact} />
                <IconLink icon={calendarIcon} to={meetingUrl} size="50px" color="#CCCCCC" external />
                <IconLink icon={userGraduateIcon} to={bookingUrl} size="50px" color="#CCCCCC" external />
                <IconLink icon={linkedInIcon} to={linkedinUrl} size="50px" color="#CCCCCC" external />
                <IconLink icon={facebookIcon} to={facebookUrl} size="50px" color="#CCCCCC" external />
            </FixedBar>
            <AppContainer>
                <Container rowBoxNum={1}>
                    <Box color="transparent">
                        <img src={logo} className="spin-item" alt="logo" />
                    </Box>
                    <Box color="transparent">
                        <Countdown />
                    </Box>
                </Container>
            </AppContainer>
            <AppFooter>
                <FooterItem>
                    <Copyright>&#169; 2022 Supernova Technology AB. All Rights Reserved.</Copyright>
                </FooterItem>
            </AppFooter>
        </MainContainer>
    );
}


const MainContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--supernova-very-dark-gray);
    color: #ffffff;
    overflow: hidden;
    z-index: 99999;
`;

const AppContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100vh;
    font-size: calc(10px + 2vmin);
`;

/* const AppHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 100%;
    height: auto;
    background-color: transparent;
`; */

const AppFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    height: auto;
    background: none repeat scroll 0 0 transparent;
    position: fixed;
    bottom: 0;
    text-align: center;
`;

const FooterItem = styled.div`
    background: none repeat scroll 0 0 transparent;
    margin: auto;
`;

const Copyright = styled.p`
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    color: var(--supernova-silver);
    font-weight: normal;
    font-size: 0.8rem;
    user-select: none;
`;
