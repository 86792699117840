import React from 'react';
import { useTranslation } from "react-i18next";
import Container from '../common/container/Container';
import Box from '../common/container/box/Box';
import Body, { Section } from '../common/container/Body';
import Title, { H2 } from '../common/container/text/Title';
import aboutuskImg from '../../assets/images/supernova_img_about_us.jpg';
import developmentProcessImg from '../../assets/images/supernova_img_project_planning.jpg';
import technologyImg from '../../assets/images/supernova_img_apple_devices.jpg';

export default function About() {
    const { t } = useTranslation("home");

    return (
        <Container id="about" rowColNum={2} useOffset>
            <Box color="#071630" mdOrder={2}>
                <Title value={t("about.ourStory.title")} />
                <Body text>
                    <Section color="#CCCCCC">{t("about.ourStory.body.part1")}</Section>
                    <Section color="#CCCCCC">{t("about.ourStory.body.part2")}</Section>
                    <Section color="#CCCCCC">{t("about.ourStory.body.part3")}</Section>
                </Body>
            </Box>
            <Box image={aboutuskImg} mdOrder={1} />
            <Box image={developmentProcessImg} mdOrder={3} />
            <Box color="#CCCCCC" mdOrder={4}>
                <Title color="#071630" value={t("about.devProcess.title")} />
                <Body text>
                    <Section color="#071630">{t("about.devProcess.body.part1")}</Section>
                    <Section color="#071630">{t("about.devProcess.body.part2")}</Section>
                </Body>
            </Box>
            <Box color="#071630" mdOrder={6}>
                <Title value={t("about.technology.title")} />
                <Body text>
                    <Section color="#CCCCCC">{t("about.technology.part1.body")}</Section>
                    <Section color="#CCCCCC">
                        <H2>{t("about.technology.part2.title")}</H2>
                        {t("about.technology.part2.body")}
                    </Section>
                    <Section color="#CCCCCC">
                        <H2>{t("about.technology.part3.title")}</H2>
                        {t("about.technology.part3.body")}
                    </Section>
                    <Section color="#CCCCCC">
                        <H2>{t("about.technology.part4.title")}</H2>
                        {t("about.technology.part4.body")}
                    </Section>
                    <Section color="#CCCCCC">
                        <H2>{t("about.technology.part5.title")}</H2>
                        {t("about.technology.part5.body")}
                    </Section>
                </Body>
            </Box>
            <Box image={technologyImg} mdOrder={5} />
    </Container>
    );
}
