import React from 'react';
import styled from 'styled-components';
import { H2 } from './container/text/Title';
import Icon from './icon/Icon';

export default function IconHeader({icon, title, size, color, center, ...rest}) {
    if(!size) size = "1.38rem";

    return (
        <Container center={center} {...rest}>
            <Icon icon={icon} size={size} color={color} />
            <H2 size={size} color={color}>{title}</H2>
        </Container>
    );
}


const Container = styled.header`
    display: flex;
    grid-gap: 1rem;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: ${({center}) => center ? "center" : "flex-start"};
    padding: 0;
    margin: 0;
`;
