import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import Icon from '../icon/Icon';
import Button from '../navigation/buttons/Button';
import {titleStyle} from '../../../style/common.css';
import closeIcon from '../../../assets/icons/xmark.svg';

export default function Modal({title, children, show, hideDefault, onClose}) {
    return (
        <Container tabIndex="-1" aria-labelledby="modalTitle" aria-hidden="true" show={show}>
            <Dialog>
                <Content>
                    <Header>
                        <Title id="modalTitle">{title}</Title>
                        <Icon icon={closeIcon} size="20px" onClick={onClose} color="#CCCCCC" />
                    </Header>
                    <Body>
                        {children}
                    </Body>
                    {!hideDefault && 
                        <Button onClick={onClose}>
                            <Icon icon={closeIcon} size="20px" color="#FFFFFF" />
                            Close
                        </Button>
                    }
                </Content>
            </Dialog>
        </Container>
    );
}


const Container = styled.div`
	z-index: 9999999;
	display: ${({show}) => (show ? 'block' : 'none')};
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width:100vw;
	background: rgba(0,0,0,0.5);
    
    body {
        overflow-y: hidden;
    }
`;

const Dialog = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 33%;
	height: auto;
    max-height: 100%;
    padding: 16px;
    margin: auto;
    border-radius: 8px;
    background-color: #444444;

    @media screen and (max-width: 1000px) {
	    width: 50%;
    }

    @media screen and (max-width: 800px) {
	    width: 100%;
	    height: 100%;
        top: 0;
        left: 0;
	    transform: none;
    }
`;

const Content = styled.div`
    display: block;
    align-items: center;
    justify-content: center;
    max-width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 8px;
    border: 0;
`;

const Body = styled.div`
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
`;

const Title = styled.h5`
    ${titleStyle}
    font-size: 1em;
    padding: 0;
    margin: 0;
`;


Modal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    hidden: PropTypes.bool.isRequired,
    hideDefault: PropTypes.bool
};

Modal.defaultProps = {
    title: "Popup",
    hidden: false,
    hideDefault: false
};
