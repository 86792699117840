import React from 'react';
import styled from 'styled-components';

//TODO: fix so bg image opacity is changed but not child components
//TODO: fix so bg image size has same height as previous box (is full height?) on mobile devices
//TODO: fix so bg image size has the correct proportion
//TODO: fix so one box can have 2+ spans and everything is centered
//TODO: fix so default min size/height can be set

/**
 * @param mdOrder order of the box on mobile devices
 */
export default function CardImage({image, minHeight, vertical, light}) {
    return (<StyledImage image={image} minHeight={minHeight} vertical={vertical} light={light} />);
}


const StyledImage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    height: 100%;
    min-height: ${({minHeight}) => minHeight ? minHeight : "456px"};
    padding: 48px;
    margin: 0;
    border-radius: ${({vertical}) => vertical ? "8px 8px 0 0" : "8px 0 0 8px"};
    background-color: var(--supernova-blue);
    background: ${({image}) => image ? `url(${image}) 50% center / cover no-repeat` : null};
    color: var(--supernova-silver);
    opacity: ${({light}) => light ? 0.5 : 1};

    @media screen and (max-width: 800px) {
        min-height: 30vh;
        border-radius: 8px 8px 0 0;
    }
`;
