import React, {Suspense} from 'react';
import styled from 'styled-components';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './style/global.css';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Maintenance from "./components/maintenance/Maintenance";
import starLogoUrl from './assets/icons/logo/Supernova_logo_star_color_v2.svg';
import ConsentBanner from './components/privacy/ConsentBanner';

function App() {
    return (
        <MainContainer>
            <Suspense fallback={<Loader />}>
                <BrowserRouter>
                    <ToastContainer theme="colored" />
                    <ConsentBanner title body />
                    <GlobalStyle />
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        {/* <Route exact path="/" element={<Maintenance />} /> */}
                        <Route path="/home" element={<Navigate replace to="/" />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/maintenance" element={<Maintenance />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </MainContainer>
    );
}

export default App;

// loading component for suspense fallback. TODO: extract to a component
const Loader = () => (
    <div>
      <img src={starLogoUrl} alt="logo" />
      <h1>loading...</h1>
    </div>
);


// TODO: extract to a component
const MainContainer = styled.div`
    background-color: var(--supernova-primary-bg);

    @media screen and (min-width: 1920px) {
        max-width: 1920px;
        margin: 0 auto;
    }
`;
