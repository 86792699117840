import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.i`
    display: flex;
    place-self: center;
    place-items: center;
    place-content: center;
    width: ${({size}) => size ? size : 'var(--supernova-icon-size)'};
    height: ${({size}) => size ? size : 'var(--supernova-icon-size)'};
    background-color: ${props => props.color ? props.color : 'var(--supernova-blue)'};
    mask: url(${props => props.url});
    mask-size: ${({size}) => size ? size : 'var(--supernova-icon-size)'} ${({size}) => size ? size : 'var(--supernova-icon-size)'};
    mask-repeat: no-repeat;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    cursor: pointer;

    &:focus, &:hover {
        opacity: ${({onClick}) => onClick ? "0.7" : "1"};
    }
`;

const Icon = ({icon, color, size, ...rest}) => (<StyledIcon url={icon} color={color} size={size} {...rest} />);

export default Icon;
