import React from 'react';
import PropTypes from "prop-types";
import Link from './Link';

const NavbarLink = ({to, label, hash, ...rest}) => (<Link to={to} label={label} hash={hash} color="inherit" nav {...rest} />);

export default NavbarLink;


NavbarLink.propTypes = {
    to: PropTypes.string.isRequired, 
    label: PropTypes.string.isRequired
};
