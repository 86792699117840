import { useState } from "react";
import _ from "lodash";
import SearchInput from '../components/common/form/SearchInput';
import Input from '../components/common/form/Input';
import TextArea from '../components/common/form/TextArea';
import Select from '../components/common/form/FormSelect';
import FormButton from '../components/common/form/FormButton';

export default function useForm(initialData, joiSchema, doSubmit, doCancel) {
    const [data, setData] = useState(initialData);
    const [errors, setErrors] = useState({});

    const validate = () => {
        const options = { abortEarly: false };
        const { error } = joiSchema.validate(data, options);

        if (!error) return null;

        const errors = {};
        for (const detail of error.details) {
            errors[detail.path[0]] = detail.message;
        }
        console.log("VALIDATION ERRORS", errors);
        return errors;
    };

    const validateProperty = ({ name, value }) => {
        const schema = joiSchema.extract(name);
        const { error } = schema.validate(value);

        if (!error) return null;
        return error.message;
    };

    const handleChange = ({ currentTarget: input }) => {
        const errorMessage = validateProperty(input);
        if (errorMessage) _.set(errors, input.name, errorMessage);
        else delete errors[input.name];
        setErrors({ ...errors });

        _.set(data, input.name, input.value);
        setData({ ...data });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = validate();
        setErrors(errors || {});
        if (errors) return;

        doSubmit();
        setData(initialData);
    };

    const handleCancel = (e) => {
        e.preventDefault();
        doCancel();
        setData(initialData);
    };

    const renderInput = (name, label, type = "text", helpText = "", isRequired = false, isInline = false, isReadOnly = false) => (
        <Input 
            type={type} 
            name={name} 
            label={label} 
            value={_.get(data, name)} 
            error={errors[name]} 
            helpText={helpText} 
            onChange={handleChange} 
            isInline={isInline} 
            isReadOnly={isReadOnly} 
            isRequired={isRequired} 
        />
    );

    const renderSearchInput = (name, label, helpText = "", isRequired = false, isInline = false, isReadOnly = false) => (
        <SearchInput 
            type="search" 
            name={name} 
            label={label} 
            value={_.get(data, name)} 
            error={errors[name]} 
            helpText={helpText} 
            onChange={handleChange} 
            isInline={isInline} 
            isReadOnly={isReadOnly} 
            isRequired={isRequired} 
        />
    );

    const renderTextArea = (name, label, helpText = "", isRequired = false, isInline = false, isReadOnly = false) => (
        <TextArea 
            name={name} 
            label={label} 
            value={_.get(data, name)} 
            error={errors[name]} 
            helpText={helpText} 
            onChange={handleChange} 
            isInline={isInline} 
            isReadOnly={isReadOnly} 
            isRequired={isRequired} 
        />
    );

    const renderSelect = (name, label, options, helpText = "", isRequired = false) => (
        <Select 
            data={options} 
            name={name} 
            label={label} 
            value={_.get(data, name)} 
            error={errors[name]} 
            helpText={helpText} 
            onChange={handleChange} 
            isRequired={isRequired} 
        />
    );

    const renderButton = (label, type = "submit", icon = "", color = "#071630", rest) => (
        <FormButton 
            type={type} 
            label={label}
            icon={icon}
            color={color}
            disabled={type === "submit" && validate()}
            {...rest}
        />
    );

    return { handleSubmit, handleCancel, renderInput, renderSearchInput, renderTextArea, renderSelect, renderButton, setErrors, data };
}
