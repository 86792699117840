import React from 'react';
import styled from 'styled-components';

export default function Footer({margin, children, ...rest}) {
    return (
        <StyledFooter margin={margin} {...rest}>{children}</StyledFooter>
    );
}


const StyledFooter = styled.footer`
    display: flex;
    grid-gap: 1rem;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
    place-content: center;
    padding: 0 32px;
    margin: ${({margin}) => margin ? margin : 0};
    margin-top: 32px;

    @media screen and (max-width: 800px) {
        display: grid;
        justify-content: stretch;
        padding: 0;
    }
`;
