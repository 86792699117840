import React from 'react';
import styled from 'styled-components';

export default function Footer() {
    const appVersion = `V${process.env.REACT_APP_VERSION}`;

    return (
        <>
            <Copyright>
                &#169; 2022 Supernova Technology AB. All Rights Reserved.
                <AppInfo> {appVersion}</AppInfo>
            </Copyright>
        </>
    );
}


const AppInfo = styled.span`
    color: var(--supernova-antracit);
    font-weight: 500;
    user-select: none;
`;

const Copyright = styled.p`
    display: block;
    margin: 0;
    padding: 1rem;
    text-align: center;
    color: var(--supernova-blue);
    font-weight: normal;
    font-size: 0.8rem;
    user-select: none;
`;
