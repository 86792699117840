import React, {useEffect, useState, useRef} from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import leftArrow from '../assets/icons/caret-left.svg';
import rightArrow from '../assets/icons/caret-right.svg';
import Icon from './common/icon/Icon';

// TODO: fix so text size on mobile devices can be set
// TODO: add draggable swipe nav for mobile to change slide

export default function Slideshow({slides, translate, mdHide, delay}) {
    const timer = delay ? delay : 4000;
    const [index, setIndex] = useState(0);
    const timeoutRef = useRef(null);
    const { t } = useTranslation("home");
    const slider = useRef(null);

    const resetTimeout = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    const generateRandom = () => {
        const max = slides.length;
        const random = Math.floor(Math.random() * (max - 1));
        return random < slides.length ? random : 0;
    }

    const nextSlide = () => {
        setIndex((prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1);
    };

    const prevSlide = () => {
        setIndex((prevIndex) => prevIndex === 0 ? slides.length - 1 : prevIndex - 1);
    };

    useEffect(() => {
        if (!Array.isArray(slides) || slides.length <= 0) {
            return null;
        }
        setIndex(generateRandom());
    }, [timer]);

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(() => setIndex((prevIndex) => prevIndex === slides.length - 1 ? 0 : prevIndex + 1), timer);
        return () => resetTimeout();
    });

    useEffect(() => {
        let isDown = false;
        let startX;
        let scrollLeft;

        document.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - document.offsetLeft;
            scrollLeft = document.scrollLeft;
            console.log("mousedown - isDown", isDown);
            console.log("mousedown - StartX", startX);
            console.log("mousedown - scrollLeft", scrollLeft);
        });

        document.addEventListener('mouseleave', () => {
            isDown = false;
            console.log("mouseleave - isDown", isDown);
        });

        document.addEventListener('mouseup', () => {
            isDown = false;
            console.log("mouseup - isDown", isDown);
        });

        document.addEventListener('movement ', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - document.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            document.scrollLeft = scrollLeft - walk;
            console.log("mousemove - isDown", isDown);
            console.log("mousemove - StartX", startX);
            console.log("mousemove - scrollLeft", scrollLeft);
            console.log("mousemove - walk", walk);
            console.log("mousemove - x", x);
        });
    });

    return (
        <Slider mdHide={mdHide}>
            <Arrow className="left" onClick={prevSlide}>
                <Icon icon={leftArrow} size="3rem" color="#071630" />
            </Arrow>
            <Arrow className="right" onClick={nextSlide}>
                <Icon icon={rightArrow} size="3rem" color="#071630" />
            </Arrow>
            {slides.map((slide, i) => {
                return (
                <Slide key={i} className={index === i ? "active" : ""} ref={slider}>
                    <SlideImage isVisible={index === i} src={slide.imageUrl} />
                    <SlideContent isVisible={index === i}>
                        <SlideText color={slide.text.color}>
                            {translate ? t(slide.text.value) : slide.text.value}
                        </SlideText>
                    </SlideContent>
                </Slide>
                );
            })}

            <Dots>
                {slides.map((_, i) => (
                    <Dot key={i}
                        className={`${index === i ? "active" : ""}`}
                        onClick={() => setIndex(i)}>
                    </Dot>
                ))}
            </Dots>
        </Slider>
    );
}


const Slider = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 800px) {
        display: ${({mdHide}) => mdHide ? "none" : "flex"};
    }
`;

const Slide = styled.div`
    opacity: 0;
    transition-duration: 2s ease;

    &.active {
        opacity: 1;
        transition-duration: 3s;
    }
`;

const SlideImage = styled.img`
    display: ${({isVisible}) => isVisible ? "flex" : "none"};
    width: 100%;
    height: auto;
`;

const SlideContent = styled.div`
    display: ${({isVisible}) => isVisible ? "flex" : "none"};
    place-items: center;
    place-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const SlideText = styled.p`
    display: flex;
    text-align: center;
    font-size: 4rem;
    color: ${({color}) => color ? color : "var(--supernova-blue)"};
    overflow: hidden;
    white-space: initial; 
    overflow-wrap: break-word;
    word-wrap: break-word;
    border-radius: 1rem;
    padding: 1rem 2rem;
    margin: 1rem;
    background-color: ${({color}) => color ? "rgb(255, 255, 255, 0.5)" : "transparent"};

    @media screen and (max-width: 800px) {
        font-size: 1.5rem;
    }
`;

const Arrow = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 3rem;
    background-color: rgb(255, 255, 255, 0.25);
    opacity: 0;
    height: 100%;
    padding: 3rem;
    z-index: 10;
    cursor: pointer;
    user-select: none;

    &:focus, &:hover {
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    &.right {
        right: 0;
    }
        
    &.left {
        left: 0;
    }

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const Dots = styled.div`
    display: flex;
    place-items: center;
    position: absolute;
    bottom: 1.25rem;
`;

const Dot = styled.div`
    display: flex;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    margin: 0 8px;
    background-color: var(--supernova-silver);
    cursor: pointer;

    &.active {
        background-color: var(--supernova-blue);
    }
`;



// TODO
/* const SlideContent = styled.div`
    display: ${({isVisible}) => isVisible ? "flex" : "none"};
    align-items: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    max-width: 100%;
    width: 1920px;
    height: 1280px;
    //background: ${({background}) => background ? `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${background}) 50% center / cover no-repeat` : "var(--supernova-blue)"};
    background-image: ${({background}) => background ? `url(${background})` : "var(--supernova-blue)"};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
`; 

const SlideText = styled.p`
    text-align: center;
    font-size: 4rem;
    color: ${({color}) => color ? color : "var(--supernova-blue)"};
    overflow: hidden;
    white-space: initial; 
    overflow-wrap: break-word;
    word-wrap: break-word;
    padding: 0 2rem;

    @media screen and (max-width: 800px) {
        font-size: 2rem;
    }
`;
*/

{/* <Slide key={i} className={index === i ? "active" : ""}>
    <SlideContent isVisible={index === i} background={slide.imageUrl}>
        <SlideText color={slide.text.color}>
            {translate ? t(slide.text.value) : slide.text.value}
        </SlideText>
    </SlideContent>
</Slide> */}