import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

export default function Container({id, rowColNum, gap, useOffset, children, ...rest}) {
    return (
        <StyledContainer id={id} rowColNum={rowColNum} gap={gap} useOffset={useOffset} {...rest}>{children}</StyledContainer>
    );
}


const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: ${({rowColNum}) => rowColNum ? `repeat(${rowColNum}, 1fr)` : "1fr"};
    grid-row-gap: ${({gap}) => gap ? gap : 0};
    align-items: center;
    align-content: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 0 32px;
    padding-top: ${({useOffset}) => useOffset ? "43px" : 0};
    margin-top: ${({useOffset}) => useOffset ? "-43px" : 0};

    @media screen and (max-width: 800px) {
        grid-template-columns: 100%; /* 1fr */
        grid-gap: 0;
        justify-content: center;
        padding-top: ${({useOffset}) => useOffset ? "85px" : 0};
        margin-top: ${({useOffset}) => useOffset ? "-85px" : 0};
    }
`;


Container.propTypes = {
    id: PropTypes.string, 
    rowColNum: PropTypes.number,
    useOffset: PropTypes.bool
};

Container.defaultProps = {
    id: "",
    rowColNum: 1,
    useOffset: false
};
