import React from 'react';
import styled from 'styled-components';

// TODO: clean up card & box, extract common style and code, remove unused parts and rename for convienence.

export default function Body({color, section, rowColNum, gap, indent, center, text, children, ...rest}) {
    return (
        <Container color={color} text={text} center={center} {...rest}>
            {section && <Section color={color}>{children}</Section>}
            {!section && <Content rowColNum={rowColNum} gap={gap} indent={indent}>{children}</Content>}
        </Container>
    );
}


const Container = styled.div`
    align-self: ${({center}) => center ? "center" : "flex-start"};
    max-width: 100%;
    padding: 0 32px;
    margin: 0;
    font-size: ${({text}) => text ? "1.2rem" : "1rem"};
    text-align: left;
    color: ${({color}) => color ? color : "var(--supernova-silver)"};

    @media screen and (max-width: 800px) {
        width: 100%;
        padding: 0;
    }
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: ${({rowColNum}) => rowColNum ? `repeat(${rowColNum}, 1fr)` : "1fr"};
    grid-column-gap: ${({gap}) => gap ? gap : "1rem"};
    flex-direction: row;
    align-self: flex-start;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-left: ${({indent}) => indent ? indent : 0};

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
    }
`;

export const Section = styled.section`
    color: ${({color}) => color ? color : "inherit"};
    background: ${({image}) => image ? `url(${image}) 50% center / cover no-repeat` : null};
    max-width: ${({maxWidth}) => maxWidth ? `calc(${maxWidth} - 100px)` : "unset"};
    padding: 0;
    margin: 24px 0;

    @media screen and (max-width: 800px) {
        max-width: unset;
    }
`;

export const TextBody = styled.p`
    font-size: ${({size}) => size ? size : "1.2rem"};
    color: ${({color}) => color ? color : "inherit"};
    line-height: 1.2;
`;

const getColumns = (columnsQty) => {
    const column = " 1fr";
    let columns = "";
    for(let i = 0; i < parseInt(columnsQty); i++) {
        columns += column;
    }
    return columns;
};
