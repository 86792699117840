import React from 'react';
import Joi from 'joi';
import { toast } from "react-toastify";
import styled from 'styled-components';
import email from "../services/emailService";
import useForm from '../hooks/useForm';
import logger from '../services/logService';
import FormModal from './common/modal/FormModal';
import Icon from './common/icon/Icon';
import Link from './common/navigation/links/Link';
import phoneIcon from '../assets/icons/phone.svg';
import sendIcon from '../assets/icons/paper-plane.svg';

function Contact({show, onHideModal}) {
    const isRequired = true;
    const initialData = { name: "", company: "", phone: "", email: "", case: "", message: "" };
    const schema = Joi.object({
        name: Joi.string().allow("").optional().label("Name"),
        company: Joi.string().allow("").optional().label("Company"),
        phone: Joi.string().allow("").optional().regex(/^[+]*[0-9]{0,3}[-]*[0-9]{2,4}[-]*[0-9]{5,7}$/).label("Phone"),
        email: Joi.string().email({ tlds: { allow: false } }).required().label("E-mail"),
        case: Joi.string().min(5).max(15).required().label("Subject"),
        message: Joi.string().min(5).max(100).required().label("Message")
    });

    const doSubmit = () => {
        try {
            email.send(data);
            toast.success("Message sent");
        } catch (error) {
            toast.error("Message cannot be sent");
            logger.error(error.response.data);
        }
        onHideModal();
    };

    const doCancel = () => {
        onHideModal();
    };

    const { handleSubmit, handleCancel, renderInput, renderTextArea, renderButton, data } = useForm(initialData, schema, doSubmit, doCancel);

    return (
        <FormModal title="Get in touch" show={show} onSubmit={handleSubmit} onCancel={handleCancel} cancelButtonLabel="Cancel">
            <Section itemScope itemType="http://schema.org/LocalBusiness">
                <h1 itemProp="name" style={{ display: "none" }}>Supernova Technology AB</h1>
                <Link to="tel:04268076" color="#FFFFFF" external itemProp="telephone">
                    <Icon icon={phoneIcon} size="20px" color="#FFFFFF" />
                    042 - 680 76
                </Link>
            </Section>
            <section>
                {renderInput("name", "Full name", "text", "Enter your firstname & lastname here")}
                {renderInput("company", "Company", "text", "Enter your company name here")}
                {renderInput("phone", "Phone", "tel", "Enter your phone number here")}
                {renderInput("email", "E-mail", "text", "Enter your e-mail address here", isRequired)}
                {renderInput("case", "Case", "text", "Describe your errand here", isRequired)}
                {renderTextArea("message", "Message", "Enter your message here", isRequired)}
                {renderButton("Send", "submit", sendIcon, "#FFFFFF", {maxWidth: true, outline: true})}
            </section>
        </FormModal>
    );
}

export default Contact;

const Section = styled.section`
    margin: 24px 4px;
`;