import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from "prop-types";
import Button from '../navigation/buttons/Button';
import Icon from '../icon/Icon';
import carretDown from '../../../assets/icons/caret-down.svg'

export default function Select({ data, initValue, primaryColor, secondaryColor, itemColor, icon, size: sizeParam, readOnly, onChange, idProperty, nameProperty, ...rest }) {
    const [currentValue, setCurrentValue] = useState('');
    const [open, setOpen] = useState(false);
    const size = sizeParam ? sizeParam : "1.25rem";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleValueChange = (value) => {
        setCurrentValue(value);
    };

    const handleChange = (item) => {
        handleValueChange(item[nameProperty]);
        // call method, if it exists
        if (onChange) onChange(item[idProperty]);
        // close, after all tasks are finished
        handleClose();
    };

    useEffect(() => {
        setCurrentValue(initValue);
    }, [initValue]);

    return (
        <Container {...rest}>
            <Button color={primaryColor} textColor={secondaryColor} size={size} onClick={handleOpen} disabled={readOnly}>
                {icon && <Icon icon={icon} color={secondaryColor} size={size} />}
                {currentValue}
                <Icon icon={carretDown} color={secondaryColor} size="1rem" />
            </Button>
            <Dropdown isVisible={open} primaryColor={primaryColor} secondaryColor={secondaryColor}>
                {data.map((item, index) => (
                    <DropdownItem
                        key={item[idProperty] + index}
                        onClick={() => handleChange(item)}
                        active={item[nameProperty] === currentValue}
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        itemColor={itemColor}
                        size={size}>
                        {item[nameProperty]}
                    </DropdownItem>
                ))}
            </Dropdown>
        </Container>
    );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    padding: 0;
    z-index: 99999;
`;

const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    place-self: center;
    place-content: center;
    place-items: center;
    position: absolute;
    top: 0;
    left: 0;
    max-height: 40vmax;
    min-width: 100%;
    padding: 0;
    margin: 0;
    outline: none;
    border-radius: 8px;
    border: 2px solid ${({primaryColor}) => primaryColor ? primaryColor : "var(--supernova-blue)"};
    background: ${({secondaryColor}) => secondaryColor ? secondaryColor : "var(--supernova-silver)"};
    transition: height 0.3s ease;
    overflow-y: auto;
    
    ${({ isVisible }) => isVisible !== true && css`
        max-height: 40px;
        display: none;
    `}
`;

const DropdownItem = styled.div`
    display: flex;
    place-items: center;
    place-content: center;
    justify-self: center;
    font-size: ${({size}) => size ? size : "1.25rem"};
    font-weight: 400;
    color: ${({itemColor}) => itemColor ? itemColor : "var(--supernova-antracit)"};
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    
    ${({ active }) => active && css`
        color: ${({primaryColor}) => primaryColor ? primaryColor : "var(--supernova-blue)"};
        font-weight: 500;
    `}
    
    &:hover, :focus, :focus:hover {
        background-color: ${({primaryColor}) => primaryColor ? primaryColor : "var(--supernova-blue)"};
        color: ${({secondaryColor}) => secondaryColor ? secondaryColor : "var(--supernova-silver)"};
    }
`;


Select.propTypes = {
    data: PropTypes.array.isRequired, // TODO: add more specific array content-type
    initValue: PropTypes.string.isRequired,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    itemColor: PropTypes.string,
    readOnly: PropTypes.bool,
    idProperty: PropTypes.string.isRequired,
    nameProperty: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

Select.defaultProps = {
    initValue: "",
    primaryColor: "var(--supernova-blue)",
    secondaryColor: "#FFFFFF",
    itemColor: "var(--supernova-antracit)",
    readOnly: false,
    idProperty: "_id",
    nameProperty: "name"
};
