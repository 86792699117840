import React from 'react';
import { Link as RRDLink, NavLink } from "react-router-dom";
import styled from 'styled-components';
import PropTypes from "prop-types";
import {linkStyle} from '../../../../style/common.css';

const StyledLink = styled(RRDLink)`
    ${linkStyle}
`;

const StyledNavLink = styled(NavLink)`
    ${linkStyle}
    padding: 8px;
`;

const StyledHashLink = styled.a`
    ${linkStyle}
`;

const StyledFakeLink = styled.p`
    ${linkStyle}
`;

const Link = ({ to, label, external, hash, nav, noLink, color, size, children, ...rest }) => (
    <>
        {noLink && <StyledFakeLink color={color} size={size} {...rest} rel="noreferrer">{label ? label : children}</StyledFakeLink>}
        {!noLink && external && <StyledHashLink href={to} color={color} size={size} rel="noreferrer" target="_blank">{label ? label : children}</StyledHashLink>}
        {!noLink && !external && hash && <StyledHashLink href={"#" + to} nav={nav} color={color} size={size} {...rest} rel="noreferrer">{label ? label : children}</StyledHashLink>}
        {!noLink && !external && !hash && nav && <StyledNavLink to={to} color={color} size={size} rel="noreferrer">{label ? label : children}</StyledNavLink>}
        {!noLink && !external && !hash && !nav && <StyledLink to={to} color={color} size={size} {...rest} rel="noreferrer">{label ? label : children}</StyledLink>}
    </>
);

export default Link;


Link.propTypes = {
    to: PropTypes.string,
    label: PropTypes.string
};
