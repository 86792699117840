import React from 'react';
import styled from 'styled-components';
import {buttonStyle, outlineButtonStyle} from '../../../../style/common.css';

const SolidButton = styled.button`
  ${buttonStyle}
`;

const OutlineButton = styled.button`
  ${outlineButtonStyle}
`;

const Button = ({ label, size, color, textColor, outline, children, ...rest }) => (
  <>
    {!outline && <SolidButton type="button" size={size} color={color} textColor={textColor} {...rest}>{label ? label : children}</SolidButton>}
    {outline && <OutlineButton type="button" size={size} color={color} textColor={textColor} {...rest}>{label ? label : children}</OutlineButton>}
  </>
);

export default Button;
