import React from 'react';
import styled from 'styled-components';

export default function IconGroup({children}) {
    return (
        <Group>{children}</Group>
    );
}


export const Group = styled.section`
    display: flex;
    gap: 0.25rem;
    padding: 0;
    margin: 0;
`;
