import React from 'react';
import PropTypes from "prop-types";
import Button from '../navigation/buttons/Button';
import Icon from '../icon/Icon';

export default function FormButton({ label, icon, color, ...rest }) {
    return (
        <Button type="submit" color={color} {...rest}>
            {icon && <Icon icon={icon} size="20px" color={color} />}
            {label}
        </Button>
    );
}


FormButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string
};

FormButton.defaultProps = {
    label: "Submit",
    icon: "",
    color: ""
};
