import React from 'react';
import styled from 'styled-components';

/**
 * @param mdOrder order of the box on mobile devices
 */
export default function Box({id, color, image, bgFixed, light, minHeight, margin, rounded, mdOrder, left, center, children}) {
    return (
        <StyledBox id={id} color={color} image={image} bgFixed={bgFixed} light={light} minHeight={minHeight} margin={margin} rounded={rounded} mdOrder={mdOrder} left={left} center={center}>{children}</StyledBox>
    );
}

//TODO: fix so bg image opacity is changed but not child components
//TODO: fix so bg image size has same height as previous box (is full height?) on mobile devices
//TODO: fix so bg image size has the correct proportion
//TODO: fix so one box can have 2+ spans and everything is centered
//TODO: fix so default min size/height can be set
//TODO: fix so border radius can be set
//TODO: fix so box order on mobile devices can be set

const StyledBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({left}) => left ? "flex-start" : "center"};
    align-content: center;
    justify-content: flex-start;
    height: 100%;
    min-height: ${({minHeight}) => minHeight ? minHeight : "456px"};
    padding: 48px;
    margin: ${({margin}) => margin ? margin : "0"};
    border-radius: ${({rounded}) => rounded ? "1rem" : "0"};
    background-color: ${({color}) => color ? color : "var(--supernova-blue)"};
    background-image: ${({image}) => image ? `url(${image})` : null};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: ${({bgFixed}) => bgFixed ? "fixed" : "inherit"};
    color: var(--supernova-silver);
    opacity: ${({light}) => light ? 0.5 : 1};

    @media screen and (max-width: 800px) {
        order: ${({mdOrder}) => mdOrder ? mdOrder : "unset"};
        align-items: ${({center}) => center ? "center" : "flex-start"};
    }
`;
