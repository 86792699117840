import React, {useState, useEffect, useRef} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from "prop-types";
import Logo from "../icon/Logo";
import Icon from '../icon/Icon';
import bars from "../../../assets/icons/bars.svg";
import close from "../../../assets/icons/xmark.svg";

export default function Navbar({ title, logo, menuLogo, navbarButtons, children }) {
    const [open, setOpen] = useState(false);
    const [sticky, setSticky] = useState(false);
    const xMenuRef = useRef();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        document.addEventListener('mousedown', closeExtendedMenu);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleResize);
          document.removeEventListener('mousedown', closeExtendedMenu);
        };
    }, []);

    const handleScroll = () => {
        handleStickyNavbarMenu();
        setOpen(false);
    };

    const handleResize = () => {
        if (window !== undefined) {
            const width = window.innerWidth;
            width > 800 && setOpen(false);
        }
    };

    const handleStickyNavbarMenu = () => {
        if (window !== undefined) {
            const yPosition = window.scrollY;
            yPosition > 80 ? setSticky(true) : setSticky(false);
        }
    };

    const closeExtendedMenu = (e) => {
        e.stopPropagation();
        if(xMenuRef.current && !xMenuRef.current.contains(e.target)) {
            setOpen(false);
        }
    };

    return (
        <Container open={open}>
            <Content open={open} ref={xMenuRef}>
                <Nav sticky={sticky}>
                    <Branding>
                        {!open && logo && <Logo icon={logo} size="60px" />}
                        {open && menuLogo && <Logo icon={menuLogo} size="60px" />}
                        {title && <Title>{title}</Title>}
                    </Branding>
                    <NavMenu sticky={sticky}>
                        {children}
                    </NavMenu>
                    <NavButtons>
                        {navbarButtons}
                    </NavButtons>
                    <MenuBar>
                        <Icon icon={open ? close : bars} size="2rem" color={open ? "var(--supernova-white)" : "var(--supernova-blue)"} onClick={() => setOpen((open) => !open)} />
                    </MenuBar>
                </Nav>
                <ExtendedNavMenu open={open}>
                    {children}
                    {navbarButtons}
                </ExtendedNavMenu>
            </Content>
        </Container>
    );
}


const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 90%;
    height: 80px;
    border: none;
    padding: 0;
    margin: auto;
    z-index: 99999;

    @media screen and (max-width: 800px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: 100vw;
        height: ${({open}) => open ? "100vh" : "fit-content"};
        margin: 0;
        background-color: ${({open}) => open ? "rgba(7, 22, 48, 0.75)" : "rgb(255, 255, 255)"};
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;

    @media screen and (max-width: 800px) {
        display: grid;
        grid-template-columns: 1fr;
        background-color: ${({open}) => open ? "var(--supernova-blue)" : "var(--supernova-white)"};
    }
`;

const Nav = styled.nav`
    display: grid;
    border: none;
    padding: 10px;
    margin: 0;
    background-color: transparent;

    ${({sticky}) => !sticky && css`
        grid-template-columns: auto 1fr auto;
        grid-gap: 50px;
        align-items: center;
        align-content: center;
        align-self: center;
        justify-content: space-between;
        width: 100%;
    `}

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr auto;
    }
`;

const Branding = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
`;

const Title = styled.div`
    display: flex;
    font-size: 40px;
    padding: 0 10px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: center;
    margin: 0 10px;
    color: var(--supernova-antracit);

    ${({sticky}) => sticky && css`
        position: fixed;
        top: 0;
        left: 50%
        margin: 0 auto;
        z-index: 99999;
        background-color: var(--supernova-white);
        border-radius: 0 0 8px 8px;
        border-left: 2px solid var(--supernova-silver);
        border-right: 2px solid var(--supernova-silver);
        border-bottom: 2px solid var(--supernova-silver);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    `}

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const ExtendedNavMenu = styled.div`
    display: ${({open}) => open ? "flex" : "none"};
    flex-direction: column;
    grid-gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0;
    color: var(--supernova-white);

    /* transition: height 0.55s ease-in; */

    @media screen and (min-width: 800px) {
        display: none;
    }
`;

const NavButtons = styled.nav`
    display: flex;
    grid-gap: 8px;
    align-items: center;
    justify-content: center;
    margin: 0 10px;

    @media screen and (max-width: 800px) {
        display: none;
    }
`;

const MenuBar = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    
    @media screen and (min-width: 800px) {
        display: none;
    }
`;


Navbar.propTypes = {
    title: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    navbarButton: PropTypes.element,
    children: PropTypes.array
};

Navbar.defaultProps = {
    title: "",
    logoUrl: ""
};
