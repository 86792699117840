import React from 'react';

export default function Projects() {
    // TODO: add past, ongoing and future projects at Supernova
    return (
        <>
            
        </>
    );
}
