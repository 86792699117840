import styled from 'styled-components';

const StyledLogo = styled.img`
    display: flex;
    width: auto;
    height: ${({size}) => size ? size : 'var(--supernova-icon-size)'};
    background-color: transparent;
    background-repeat: no-repeat;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    outline: none;
    border: none;

    &:focus, &:hover {
        opacity: 1;
    }

    @media only screen and (max-width: 800px) {
        height: calc(var(--supernova-icon-size));
    }
`;

const Logo = ({icon, size, ...rest}) => (<StyledLogo src={icon} size={size} {...rest} alt="logo" />);

export default Logo;
