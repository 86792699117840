import React from 'react';
import { useTranslation } from "react-i18next";
import Container from '../common/container/Container';
import Box from '../common/container/box/Box';
import Body, { Section } from '../common/container/Body';
import Footer from '../common/container/Footer';
import Title, { H2 } from '../common/container/text/Title';
import LinkButton from '../common/navigation/buttons/LinkButton';
import { meetingUrl } from "../../config.json";

export default function Quotation() {
    const { t } = useTranslation(["home", "common"]);

    return (
        <Container id="quotation" rowColNum={1} useOffset>
            <Box color="#FFFFFF">
                <Title value={t("quotation.title")} color="#071630" />
                <Body color="#071630" text rowColNum={2} gap="8rem" center>
                    <Section maxWidth="800px">
                        <H2 color="#071630">{t("quotation.part1.title")}</H2>
                        {t("quotation.part1.body")}
                    </Section>
                    <Section maxWidth="800px">
                        <H2 color="#071630">{t("quotation.part2.title")}</H2>
                        {t("quotation.part2.body")}
                    </Section>
                </Body>
                <Footer>
                    <LinkButton inline outline hash to="contact" label={t("common:button.requestQuotation")} size="1.2rem" />
                    <LinkButton inline outline external to={meetingUrl} label={t("common:button.bookMeeting")} size="1.2rem" />
                </Footer>
            </Box>
    </Container>
    );
}
