import React from 'react';
import { useTranslation } from "react-i18next";
import BulletList, { ListItem } from '../../common/list/BulletList';
import Box from '../../common/container/box/Box';
import Container from '../../common/container/Container';
import Body, { Section } from '../../common/container/Body';
import Title, { H2, H3, SubTitle } from '../../common/container/text/Title';
import Link from '../../common/navigation/links/Link';

export default function CookiePolicy() {
    const { t } = useTranslation("policy");

    window.scrollTo(0, 0);

    return (
        <Container id="cookiePolicy" rowBoxNum={1}>
            <Box color="#FFFFFF">
                <Title color="#071630" title={t("cookie.title")} />
                <SubTitle color="#071630">{t("cookie.subtitle")}</SubTitle>
                <Body text>
                    <Section color="#071630">{t("cookie.part1.body")}</Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part2.title")}</H2>
                        {t("cookie.part2.body")}
                    </Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part3.title")}</H2>
                        {t("cookie.part3.body")}
                    </Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part4.title")}</H2>
                        {t("cookie.part4.body")}
                        <Link inline external size="1.2rem" color="var(--supernova-soft-blue)"
                            to={t("cookie.part4.link.url")}
                            label={t("cookie.part4.link.label")} />
                    </Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part5.title")}</H2>
                        <BulletList>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item1.title")}</H3>
                                <p>{t("cookie.part5.list.item1.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item2.title")}</H3>
                                <p>{t("cookie.part5.list.item2.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item3.title")}</H3>
                                <p>{t("cookie.part5.list.item3.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item4.title")}</H3>
                                <p>{t("cookie.part5.list.item4.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item5.title")}</H3>
                                <p>{t("cookie.part5.list.item5.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item6.title")}</H3>
                                <p>{t("cookie.part5.list.item6.body")}</p>
                            </ListItem>
                            <ListItem>
                                <H3 color="#071630">{t("cookie.part5.list.item7.title")}</H3>
                                <p>{t("cookie.part5.list.item7.body")}</p>
                            </ListItem>
                        </BulletList>
                    </Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part6.title")}</H2>
                        <BulletList>
                            <ListItem>
                                <p>{t("cookie.part6.list.item1.part1")}</p>
                                <p>{t("cookie.part6.list.item1.part2")}</p>
                            </ListItem>
                            <ListItem>
                                <p>{t("cookie.part6.list.item2")}</p>
                            </ListItem>
                            <ListItem>
                                <p>{t("cookie.part6.list.item3.part1")}</p>
                                <p>{t("cookie.part6.list.item3.part2")}</p>
                            </ListItem>
                            <ListItem>
                                <p>{t("cookie.part6.list.item4")}</p>
                            </ListItem>
                        </BulletList>
                    </Section>
                    <Section color="#071630">
                        <H2 color="#071630">{t("cookie.part7.title")}</H2>
                        <p>{t("cookie.part7.body.part1")}</p>
                        {t("cookie.part7.body.part2.body")}
                        <Link inline external size="1.2rem" color="var(--supernova-soft-blue)"
                            to={t("cookie.part7.body.part2.link.url")}
                            label={t("cookie.part7.body.part2.link.label")} />
                        <p>{t("cookie.part7.body.part3.body")}</p>

                        <BulletList>
                            <ListItem>
                                <Link external size="1.2rem" color="var(--supernova-soft-blue)"
                                    to={t("cookie.part7.body.part3.list.item1.link.url")}
                                    label={t("cookie.part7.body.part3.list.item1.link.label")} />
                            </ListItem>
                            <ListItem>
                                <p>{t("cookie.part7.body.part3.list.item2")}</p>
                            </ListItem>
                        </BulletList>
                    </Section>
                </Body>
            </Box>
        </Container>
    );
}
