import React from 'react';
import styled from 'styled-components';

export default function Label({name, label, color, ...rest}) {
    return (<StyledLabel id={name + "Label"} htmlFor={name} color={color} {...rest}>{label}</StyledLabel>);
}


const StyledLabel = styled.label`
    display: block;
    position: absolute;
    top: 0.75rem;
    left: 0.5rem;
    height: 100%;
    color: ${({color}) => color ? color: "var(--supernova-antracit)"};
    word-wrap: normal;
    text-transform: none;
    line-height: inherit;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
    margin: 0;
    border: 1px solid transparent;
    font-weight: 600;
    opacity: 0.45;
    pointer-events: none;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
`;
