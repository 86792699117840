import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from './common/form/Select';
import languageIcon from '../assets/icons/supernova_icon_language.svg';

// TODO: check i18n.language format, can sv-SE be changed to sv?

export default function LanguageSelector({ ...rest }) {
    const { i18n } = useTranslation();
    const data = [{ id: "sv", name: "SV" }, { id: "en", name: "EN" }]

    const changeLang = (lang) => {
        i18n.changeLanguage(lang);
    };

    const getValueById = (id) => {
        const items = data.filter((item) => id.startsWith(item.id));
        return items[0].name;
    };

    return (
        <Select
            {...rest}
            data={data}
            icon={languageIcon}
            initValue={getValueById(i18n.language)}
            onChange={lang => changeLang(lang)}
            idProperty="id" />
    );
}
