import React from 'react';
import { useTranslation } from "react-i18next";
import Slideshow from '../Slideshow';
import slides from "../../data/slides.json";
import LinkButton from '../common/navigation/buttons/LinkButton';
import Container from '../common/container/Container';
import Title from '../common/container/text/Title';
import Box from '../common/container/box/Box';
import Body from '../common/container/Body';
import Footer from '../common/container/Footer';
import BulletList, { ListItem } from '../common/list/BulletList';
import IconHeader from '../common/IconHeader';
import { meetingUrl } from "../../config.json";
import smartComputerIcon from '../../assets/icons/supernova_icon_smart_computer.svg';
import padlockIcon from '../../assets/icons/supernova_icon_padlock.svg';
import scalableIcon from '../../assets/icons/supernova_icon_scalable.svg';
import customizationIcon from '../../assets/icons/supernova_icon_customization.svg';
import supportIcon from '../../assets/icons/supernova_icon_support.svg';
import humanBulbIcon from '../../assets/icons/supernova_icon_human_bulb.svg';
import supernovaArrowIcon from '../../assets/icons/logo/Supernova_logo_arrow_color_v2.svg';
import Card from '../common/container/card/Card';
import CardTitle from '../common/container/card/CardTitle';
import CardContent from '../common/container/card/CardContent';

export default function Intro() {
    const { t } = useTranslation(["home", "common"]);
    return (
        <>
            <Container id="intro">
                <Slideshow translate slides={slides} delay={7000} />
            </Container>
            {/* <Container id="intro" rowColNum={1} useOffset>
                    <Box center color="transparent">
                        <Title color="#071630" value={t("intro.info.title")} />
                        <Body rowColNum={3} gap="4rem" center>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={smartComputerIcon} title={t("intro.info.part1.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem>{t("intro.info.part1.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part1.list.item2")}</ListItem>
                                        <ListItem>{t("intro.info.part1.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part1.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={padlockIcon} title={t("intro.info.part2.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem>{t("intro.info.part2.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part2.list.item2")}</ListItem>
                                        <ListItem>{t("intro.info.part2.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part2.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={humanBulbIcon} title={t("intro.info.part3.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part3.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part3.list.item2")}</ListItem>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part3.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part3.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={scalableIcon} title={t("intro.info.part4.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part4.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part4.list.item2")}</ListItem>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part4.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part4.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={customizationIcon} title={t("intro.info.part5.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part5.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part5.list.item2")}</ListItem>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part5.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part5.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                            <Card color="#FFF">
                                <CardTitle>
                                    <IconHeader icon={supportIcon} title={t("intro.info.part6.title")} size="4rem" color="#FFF" />
                                </CardTitle>
                                <CardContent left>
                                    <BulletList image={supernovaArrowIcon}>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part6.list.item1")}</ListItem>
                                        <ListItem>{t("intro.info.part6.list.item2")}</ListItem>
                                        <ListItem image={supernovaArrowIcon}>{t("intro.info.part6.list.item3")}</ListItem>
                                        <ListItem>{t("intro.info.part6.list.item4")}</ListItem>
                                    </BulletList>
                                </CardContent>
                            </Card>
                        </Body>
                        <Footer margin="2rem">
                            <LinkButton inline outline hash to="quotation" label={t("common:button.requestQuotation")} size="1.2rem" />
                            <LinkButton inline outline external to={meetingUrl} label={t("common:button.bookMeeting")} size="1.2rem" />
                        </Footer>
                    </Box>
                </Container> */}
            </>
    );
}
