import React from 'react';
import PropTypes from "prop-types";
import Link from '../links/Link';
import Button from './Button';

const LinkButton = ({ to, label, size, color, external, hash, outline, children, ...rest }) => (
    <Link to={to} external={external} hash={hash} {...rest}>
        <Button outline={outline} size={size} color={color}>{label ? label : children}</Button>
    </Link>
);

export default LinkButton;


LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string
};
    