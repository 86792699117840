import React from 'react';
import styled from 'styled-components';

//TODO: fix so one box can have 2+ spans and everything is centered
//TODO: fix so default min size/height can be set

/**
 * @param mdOrder order of the box on mobile devices
 */
export default function CardTitle({color, light, center, children}) {
    return (<StyledCardTitle color={color} center={center} light={light}>{children}</StyledCardTitle>);
}


const StyledCardTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${({center}) => center ? "center" : "flex-start"};
    align-content: center;
    justify-content: flex-start;
    height: 100%;
    padding: 48px;
    margin: 0;
    border-radius: 8px 8px 0 0;
    background-color: ${({color}) => color ? color : "var(--supernova-blue)"};
    color: #FFF;
    opacity: ${({light}) => light ? 0.5 : 1};
`;
