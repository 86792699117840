import React from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import Navbar from '../components/common/navigation/Navbar';
import NavbarLink from '../components/common/navigation/links/NavbarLink';
import LinkButton from './common/navigation/buttons/LinkButton';
import Intro from "./home/Intro";
import Services from "./home/Services";
import Projects from "./home/Projects";
import About from "./home/About";
import Contact from "./home/Contact";
import LanguageSelector from './LanguageSelector';
import Footer from './home/Footer';
import Quotation from './home/Quotation';
import { meetingUrl } from "../config.json";
import starTextLogoUrl from '../assets/icons/logo/Supernova_logo_star_text_color_v2.svg';
import starTextLogoWhiteUrl from '../assets/icons/logo/Supernova_logo_star_text_white_v2.svg';

export default function Home() {
    const { t } = useTranslation();

    // TODO: fix margins, paddings and other spaces
    // TODO: decide which mesurement units to use
    // TODO: decide which color type to use
    // TODO: use supernova colors instead of hex codes.
    // TODO: move common components to common folder... subfolder?
    // TODO: add common button/link text style in a separate rule
    // TODO: add delay animation to scroll
    // TODO: fix performance issue, scroll lagg...
    // TODO: fix form select, preselected value should be empty not the first option in list.
    // TODO: add 'job application' to case options
    // TODO: add attachment uploader for contact form
    // TODO: form validation should only be triggered on change/edit
    // TODO: add 2rem to Footer in Intro after changing grid to 1fr in Container.
    // TODO: add drag support for slideshow
    // TODO: add iPad support
    // TODO: make the dropdown list appeare under the select, fix border radius to be viewd as a single component.

    /* TODO: add this to main page 
    useEffect(() => {
        if (window.location.href.includes("privacy-policy") || window.location.href.includes("site-notice")) {
            document.body.style.overflow = 'scroll'
          } else if (Cookie.get("consent") === undefined || debug) {
            document.body.style.overflow = 'hidden';
          }
    }, []); */

    const getNavbarButtons = () => (
        <>
            <LanguageSelector />
            <LinkButton label={t("button.bookMeeting")} to={meetingUrl} external />
        </>
    );

    return (
        <>
            <Navbar logo={starTextLogoUrl} menuLogo={starTextLogoWhiteUrl} navbarButtons={getNavbarButtons()}>
                <NavbarLink hash to="intro" label={t("navigation.navbar.links.intro")} />
                <NavbarLink hash to="services" label={t("navigation.navbar.links.services")} />
                {/* <NavbarLink hash to="projects" label={t("navigation.navbar.links.projects")} /> */}
                <NavbarLink hash to="about" label={t("navigation.navbar.links.about")} />
                <NavbarLink hash to="contact" label={t("navigation.navbar.links.contact")} />
            </Navbar>

            <Sections>
                <Intro />
                <Services />
                <Projects />
                <About />
                <Quotation />
                <Contact />
                <Footer />
            </Sections>
        </>
    );
}


// TODO: extract to a component
const Sections = styled.section`

    @media screen and (max-width: 800px) {
        padding-top: 80px;
    }
`;
