import React from 'react';
import { useTranslation } from "react-i18next";
import LinkButton from '../common/navigation/buttons/LinkButton';
import Container from '../common/container/Container';
import CardContent from '../common/container/card/CardContent';
import CardImage from '../common/container/card/CardImage';
import Card from '../common/container/card/Card';
import Body from '../common/container/Body';
import Title from '../common/container/text/Title';
import Footer from '../common/container/Footer';
import consultantImg from '../../assets/images/supernova_img_women_work.jpg';
import stydyImg from '../../assets/images/supernova_img_woman_study.jpg';
import workdeskImg from '../../assets/images/supernova_img_workdesk.jpg';
import { bookingUrl } from "../../config.json";

export default function Services() {
    const { t } = useTranslation(["home", "common"]);

    return (
        <Container id="services" rowColNum={1} gap="8px" useOffset>
            <Card color="#CCCCCC" rowColNum={2}>
                <CardImage image={consultantImg} />
                <CardContent>
                    <Title value={t("services.consulting.title")} color="#071630" />
                    <Body text section color="#061730">{t("services.consulting.body")}</Body>
                    <Footer>
                        <LinkButton outline hash to="contact" label={t("common:button.contactUs")} size="1.2rem" />
                    </Footer>
                </CardContent>
            </Card>

            <Card color="#CCCCCC" rowColNum={2}>
                <CardImage image={stydyImg} />
                <CardContent>
                    <Title value={t("services.courses.title")} color="#071630" />
                    <Body text section color="#061730">{t("services.courses.body")}</Body>
                    <Footer>
                        <LinkButton outline external to={bookingUrl} label={t("services.button.courses")} size="1.2rem" />
                    </Footer>
                </CardContent>
            </Card>

            <Card color="#CCCCCC" rowColNum={2}>
                <CardImage image={workdeskImg} />
                <CardContent>
                    <Title value={t("services.development.title")} color="#071630" />
                    <Body text section color="#061730">{t("services.development.body")}</Body>
                    <Footer>
                        <LinkButton outline hash to="quotation" label={t("common:button.requestQuotation")} size="1.2rem" />
                    </Footer>
                </CardContent>
            </Card>
    </Container>
    );
}
