import http from "./httpService";

const ipEndpoint = "https://geolocation-db.com/json/";

export const getIP = async () => {
  const result = await http.get(ipEndpoint);
  return result.data.IPv4;
};

const networkService = { 
  getIP 
};

export default networkService;
