import React from 'react';
import { Link } from 'react-router-dom';
import notFoundImg from '../assets/images/404_error_page_bg.svg';
import exitImg from '../assets/images/404_error_page_exit_button.svg';

function NotFound() {
    // TODO: get previous URL to log the wrong URL the user tried to access.

    // useEffect(() => {
    //     logger.log("User tried to navigate to: " + location.pathname);
    // });

    return (
        <>
            <img src={notFoundImg} className="responsive-img" alt="page not found" />
            <Link to="/">
                <img src={exitImg} className="fixed-bottom-end responsive-img-10 me-5" alt="page not found - return to first page" />
            </Link>
        </>
    );
}

export default NotFound;
