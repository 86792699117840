import React from 'react';
import styled from 'styled-components';

//TODO: fix so bg image opacity is changed but not child components
//TODO: fix so bg image size has same height as previous box (is full height?) on mobile devices
//TODO: fix so bg image size has the correct proportion
//TODO: fix so one box can have 2+ spans and everything is centered
//TODO: fix so default min size/height can be set

/**
 * @param mdOrder order of the box on mobile devices
 */
export default function Card({color, image, rowColNum, mdOrder, light, children}) {
    return (<StyledCard color={color} image={image} rowColNum={rowColNum} mdOrder={mdOrder} light={light}>{children}</StyledCard>);
}


const StyledCard = styled.div`
    display: grid;
    grid-template-columns: ${({rowColNum}) => rowColNum ? `repeat(${rowColNum}, 1fr)` : "1fr"};
    grid-column-gap: ${({gap}) => gap ? gap : "0"};
    align-items: center;
    align-content: flex-start;
    align-self: stretch;
    justify-content: space-between;
    padding: 0;
    margin: 2rem;
    border-radius: 8px;
    background-color: ${({color}) => color ? color : "rgb(255, 255, 255)"};
    background: ${({image}) => image ? `url(${image}) 50% center / cover no-repeat` : null};
    color: var(--supernova-blue);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    opacity: ${({light}) => light ? 0.5 : 1};

    @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
        justify-content: center;
        order: ${({mdOrder}) => mdOrder ? mdOrder : "unset"};
    }
`;
