import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import InputContainer from "./InputContainer";
import { errorTextStyle, hintStyle, inputStyle } from '../../../style/common.css';
import Label from './Label';

export default function Input({ name, label, value, error, helpText, required, readOnly, inline, ...rest }) {
    return (
        <InputContainer error={error} inline={inline}>
            <StyledInput
                {...rest}
                id={name}
                name={name}
                autoComplete={name}
                value={value}
                aria-label={name}
                aria-labelledby={name + "Label"}
                min="0"
                required={required}
                readOnly={readOnly}
                disabled={readOnly} />
            {label && <Label name={name} label={label} />}
            {error && helpText && <Hint id={name + "Hint"}>{helpText}</Hint>}
            {error && <ErrorText id={name + "Validation"}>{error}</ErrorText>}
        </InputContainer>
    );
}


const StyledInput = styled.input`
    ${inputStyle}
`;

const Hint= styled.p`
    ${hintStyle}
`;

const ErrorText = styled.p`
    ${errorTextStyle}
`;


Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    error: PropTypes.string,
    helpText: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    inline: PropTypes.bool
};

Input.defaultProps = {
    name: "",
    label: "",
    value: "",
    required: false,
    readOnly: false,
    inline: false
};
