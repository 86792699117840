import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import logger from "./services/logService";
import './i18n/i18n';
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/css/supernova.css";
import './assets/css/supernova-animation.css';

logger.init();

const container = document.getElementById("root");
ReactDOM.createRoot(container).render(<App />);
