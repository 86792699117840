import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import InputContainer from './InputContainer';
import Label from './Label';
import { errorTextStyle, hintStyle, inputStyle } from '../../../style/common.css';

export default function TextArea({ name, label, value, error, helpText, required, readOnly, inline, ...rest }) {
    return (
        <InputContainer error={error} inline={inline}>
            <StyledTextArea
                {...rest}
                id={name}
                name={name}
                autoComplete={name}
                value={value}
                aria-label={name}
                aria-labelledby={name + "Label"}
                required={required}
                readOnly={readOnly}
                disabled={readOnly} />
            {label && <Label name={name} label={label} />}
            {error && helpText && <Hint id={name + "Hint"}>{helpText}</Hint>}
            {error && <ErrorText id={name + "Validation"}>{error}</ErrorText>}
        </InputContainer>
    );
}


const StyledTextArea = styled.textarea`
    ${inputStyle}
    height: 10rem;
    resize: none;
`;

const Hint = styled.p`
    ${hintStyle}
`;

const ErrorText = styled.p`
    ${errorTextStyle}
`;


TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    error: PropTypes.string,
    helpText: PropTypes.string,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    inline: PropTypes.bool
};

TextArea.defaultProps = {
    name: "",
    label: "",
    value: "",
    className: "",
    required: false,
    readOnly: false,
    inline: false
};
