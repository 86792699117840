import http from "../services/httpService";
import { apiBaseUrl } from "../config.json";

const apiEndpoint = apiBaseUrl + "/communication/email";

const emailService = {
  send: (email) => {
    return http.post(apiEndpoint, {
      sender: email.name,
      company: email.company,
      phoneNumber: email.phone,
      replyToAddress: email.email,
      subject: email.case,
      body: email.message
    });
  }
};

export default emailService;
