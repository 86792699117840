import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> learn more: https://github.com/i18next/i18next-http-backend
  .use(backend)
  // detect user language -> learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next - for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: 'languageOnly',
    //lng: "en",   //default language
    fallbackLng: "en", //when specified language translations not present then fallbacklang translations loaded.
    supportedLngs: ["sv", "en"],
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand
    Format: /{{lng}}/{{ns}}.json */
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
