import React from 'react';
import styled from 'styled-components';

export default function FixedBar({top, bottom, left, right, color, children}) {
    return (
        <Container top={top} bottom={bottom} left={left} right={right} color={color}>{children}</Container>
    );
}
// TODO: add style for left & right like top & bottom

const Container = styled.div`
    display: grid;
    grid-auto-flow: column;
    flex-direction: column;
    grid-gap: 32px;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: fixed;
    top: ${({top}) => top ? (top === true) ? "-1px" : top : "auto"};
    bottom: ${({bottom}) => bottom ? (bottom === true) ? "-1px" : bottom : "auto"};
    left: ${({left}) => left ? left : "auto"};
    right: ${({right}) => right ? right : "auto"};
    padding: 8px 16px;
    margin: auto;
    margin-bottom: 16px;
    border-top-left-radius: ${({top}) => top === true ? "0" : "8px"};
    border-top-right-radius: ${({top}) => top === true ? "0" : "8px"};
    border-bottom-left-radius: ${({bottom}) => bottom === true ? "0" : "8px"};
    border-bottom-right-radius: ${({bottom}) => bottom === true ? "0" : "8px"};
    border: 1px solid ${({color}) => color ? color : "var(--supernova-silver)"};
    outline: none;
    opacity: 1;
    background-color: ${({color}) => color ? color : "var(--supernova-blue)"};
    z-index: 9999999;

    @media only screen and (max-width: 800px) {
        justify-content: center;
        top: -1px;
        bottom: auto;
        left: -1px;
        right: -1px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-left-color: ${({color}) => color ? color : "var(--supernova-blue)"};
        border-right-color: ${({color}) => color ? color : "var(--supernova-blue)"};
        max-width: 100%;
        margin: 0 auto;
    }

    @media only screen and (max-width: 400px) {
        display: none;
    }
`;
