import { css } from "styled-components";

export const buttonStyle = ({maxWidth, color, textColor, size}) => css`
  display: flex;
  flex: 1 1 0px;
  grid-gap: 8px;
  place-items: center;
  place-content: center;
  width: ${maxWidth ? "100%" : "auto"}; /* TODO: remove when contact form is rewritten. */
  max-height: 100%;
  padding: 10px 15px;
  margin: auto;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: ${size ? size : "1.25rem"};
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  opacity: 1;
  background-color: ${color ? color : "var(--supernova-blue)"};
  color: ${textColor ? textColor : "rgb(255,255,255)"};
  cursor: pointer;
  pointer-events: auto;
  transition: all 0.3s ease-in-out;

  &:focus, &:hover {
    opacity: 0.7;
    color: ${textColor ? textColor : "rgb(255,255,255)"};
    transition: all 0.3s ease-in-out;
  }

  &:disabled, &[aria-disabled="true"], &.disabled {
    opacity: 0.5;
    color: ${textColor ? textColor : "rgb(255,255,255)"};
    cursor: default;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }
`;

export const outlineButtonStyle = ({color}) => css`
  ${buttonStyle}
  border: 2px solid ${color ? color : "var(--supernova-blue)"};
  background-color: transparent;
  color: ${color ? color : "var(--supernova-blue)"};

  &:focus, &:hover {
    color: ${color ? color : "var(--supernova-blue)"};
  }
`;

export const inputStyle = ({width, color}) => css`
    display: block;
    width: ${width ? width : "100%"};
    color: ${color ? color : "var(--supernova-text-color)"};
    background-color: transparent;
    word-wrap: normal;
    text-transform: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0.25rem 0.65rem;
    padding-top: 1.6rem;
    margin: 0;
    border: none;
    
    &:focus-visible {
      outline: none;
    }

    &:focus {
      background-color: transparent;
      box-shadow: none;
      border: none;
    }

    &:-webkit-autofill, 
    &:-webkit-autofill:focus, 
    &:-internal-autofill-selected {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
`;

export const linkStyle = ({size, color, nav}) => css`
  display: flex;
  grid-gap: 8px;
  align-items: center;
  align-self: flex-start;
  font-size: ${size ? size : "1.25rem"};
  font-weight: 500;
  text-decoration: none;
  color: ${color ? color : "var(--supernova-antracit)"};
  padding: ${nav ? "8px" : "0"};
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &.active {
    color: var(--supernova-blue);
    cursor: default;
  }

  &:not(.active):hover {
    opacity: 0.7;
    color: ${color ? color : "var(--supernova-antracit)"};
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 800px) {
    align-self: center;
  }
`;

export const titleStyle = ({size, color}) => css`
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    font-size: ${size ? size : "2.75rem"};
    font-weight: 500;
    text-transform: uppercase;
    color: ${color ? color : "var(--supernova-silver)"};
    user-select: none;
`;

export const hintStyle= css`
    display: block;
    color: var(--supernova-antracit);
    word-wrap: normal;
    text-transform: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 4px 0;
    padding-left: 0.65rem;
`;

export const errorTextStyle = css`
    display: block;
    color: var(--supernova-error);
    word-wrap: normal;
    text-transform: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 4px 0;
    padding-left: 0.65rem;
`;

export const validFormStyle = css`
    color: var(--supernova-success);
`;

export const invalidFormStyle = css`
  color: var(--supernova-error);
`;
