import React from 'react';
import styled from 'styled-components';
import {titleStyle} from '../../../../style/common.css';

export default function Title({value, color, ...rest}) {
    return (
        <MainTitle color={color} {...rest}>{value}</MainTitle>
    );
}


const MainTitle = styled.h1`
    ${titleStyle}
    font-size: 2.75rem;
    padding: 0 32px;

    @media screen and (max-width: 800px) {
        font-size: 1.8rem;
        padding: 0;
    }
`;

export const SubTitle = styled.h2`
    ${titleStyle}
    font-size: 1.04rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-top: -1rem;
    margin-bottom: 1rem;
`;

export const H2 = styled.h2`
    ${titleStyle}
    font-size: 1.38rem;
    padding: 8px 0;
    margin: 0;
    margin-top: 8px;
`;

export const H3 = styled.h3`
    ${titleStyle}
    font-size: 1.04rem;
    font-weight: 600;
    padding: 8px 0;
    margin: 0;
    margin-top: 8px;
`;

export const H4 = styled.h4`
    ${titleStyle}
    font-size: 0.69rem;
    padding: 0;
    padding-left: ${({indent}) => indent ? indent : 0};
    margin: 0;
`;
