import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
import { H3 } from '../common/container/text/Title';
import { TextBody } from '../common/container/Body';
import networkService from "../../services/networkService";
import Button from '../common/navigation/buttons/Button';
import CookiePolicy from './policy/CookiePolicy';
import { cookiesConsentKey } from "../../config.json";

// TODO: add cookie settings
// TODO: add read more link/page
// TODO: add position choice
// TODO: make common, add dynamic title/body

// TODO: create Cookie policy
// TODO: create Privacy policy

// TODO: add to to setCookiesAccepted, enableGoogleAnalytics();
// TODO: add to to setCookiesAccepted, enableGoogleAdsense();
// TODO: add event tracking, push(["trackEvent", "consent", "true"])

// We use cookies and other tracking technologies to improve your browsing experience on our website, 
// to show you personalized content and targeted ads, to analyze our website traffic, 
// and to understand where our visitors are coming from.

/* TODO: update text
Vi använder kakor (cookies) //title
På supernovait.se använder vi nödvändiga kakor för att webbplatsen ska fungera på ett bra sätt för dig. Vi använder också kakor för webbanalys så vi kan förbättra vår webbplats.

Vad är kakor? //link
Hantera kakor //button
Acceptera kakor //button */

export default function ConsentBanner({ title, body }) {
    const { t } = useTranslation(["common", "policy"]);
    const [hide, setHide] = useState(false);
    const [showPolicy, setShowPolicy] = useState(false);
    const [cookies, setCookie] = useCookies([cookiesConsentKey]);
    const expirationInDays = 365;

    const setCookiesAccepted = async () => {
        const ipAddress = await networkService.getIP();
        const cookieData = { isAccepted: true, date: new Date(), ip: ipAddress };
        const cookieOptions = { path: "/", sameSite: "strict", expires: getExpirationDate() };
        setCookie(cookiesConsentKey, cookieData, cookieOptions);
        setHide(true);
    };

    const isCookiesAccepted = () => {
        const cookiesConsent = cookies[cookiesConsentKey];
        return cookiesConsent && cookiesConsent.isAccepted;
    };

    const getExpirationDate = () => {
        const result = new Date();
        result.setDate(result.getDate() + expirationInDays);
        return result;
    }

    const isAccepted = isCookiesAccepted();
    useEffect(() => {
        setHide(isAccepted);
    }, [isAccepted]);

    return (
        <>
            <Container hide={hide}>
                <Content vertical>
                    {title && <H3>{t("consentBanner.title")}</H3>}
                    {body && <TextBody size="1rem" color="#CCCCCC">{t("consentBanner.body")}</TextBody>}
                </Content>
                <Content width="40%">
                    <Button outline color="#FFFFFF" size="1rem" onClick={() => setShowPolicy(true)}>{t("consentBanner.button.readMore")}</Button>
                    <Button outline color="#FFFFFF" size="1rem" onClick={setCookiesAccepted}>{t("consentBanner.button.accept")}</Button>
                </Content>
            </Container>

            {showPolicy && <CookiePolicy />}
            {showPolicy && <Button size="1rem" onClick={() => setShowPolicy(false)} style={{"margin-bottom": "30rem"}}>{t("policy:cookie.button.close")}</Button>}
        </>
    );
}


const Container = styled.div`
    display: ${({ hide }) => hide ? "none" : "flex"};
    flex-direction: row;
    grid-gap: 1rem;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1.5rem;
    border-top: 1px solid var(--supernova-silver);
    background-color: var(--supernova-blue);
    z-index: 99999;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: ${({ vertical }) => vertical ? "column" : "row"};
    grid-gap: 1rem;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
    width: ${({ width }) => width ? width : "100%"};
`;
