import { createGlobalStyle } from "styled-components";

/* Core styles */
const GlobalStyle = createGlobalStyle`
    * { /* *, *::before, *::after */
        box-sizing: border-box;
        z-index: 0;
        padding: 0px;
        margin: 0px;
        font-family: "Helvetica Neue", "TeXGyreHeros", Helvetica, sans-serif; /* "TeXGyreHerosCondensed" */
        color: var(--supernova-text-color);
    }

    html {
        scroll-behavior: smooth;
        overflow: auto;
        overflow: overlay;
        scrollbar-gutter: auto;
    }

    body {
        display: block;
        background-color: var(--supernova-antracit);

        @media screen and (max-width: 800px) {
            background-color: var(--supernova-primary-bg);
        }
    }
`;

export default GlobalStyle;
