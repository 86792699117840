import React from 'react';
import styled from 'styled-components';

export default function InputContainer({ error, inline, children, ...rest }) {
    return (
        <Container error={error} inline={inline} {...rest}>{children}</Container>
    );
}

const Container = styled.div`
    display: ${({inline}) => inline ? "inline-block" : "block"};
    position: relative;
    width: ${({inline}) => inline ? "auto" : "100%"};
    max-width: 100%;
    line-height: 1.5;
    font-size: 1rem;
    font-weight: 400;
    color: var(--supernova-blue);
    background-color: #FFFFFF;
    padding: 0;
    padding-bottom: ${({error}) => error ? "1rem" : "0"};
    border: ${({error}) => error ? "3px solid var(--supernova-error)" : "1px solid var(--supernova-antracit)"};
    border-radius: 0.25rem;
    appearance: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
